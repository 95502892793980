import { Domain } from "frontend/lib/get-domain";
import {
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { resolveEntries } from "./resolve-entries";
import { resolveEntryUrl } from "./resolve-entry-url";
import { isContentType } from "frontend/contentful/lib/is-content-type";

interface ResolvePersonLinkArgs {
  entry?: EntryRef<
    PersonEntry | PersonWrapperEntry | SchoolLeadershipPersonDetailsEntry
  >;
  domain: Domain;
  includes: ContentfulIncludes;
}

export function resolvePersonLink({
  entry,
  domain,
  includes,
}: ResolvePersonLinkArgs) {
  let url = "";
  let person = resolveEntry(includes, entry);
  if (!person) return "";

  if (
    isContentType<SchoolLeadershipPersonDetailsEntry>(
      "schoolLeadershipPersonDetails",
      person,
    )
  ) {
    url = resolveEntry(includes, person.fields.link)?.fields.url || "";
    person = resolveEntry(includes, person.fields.person);
  }

  const bioUrl = isContentType<PersonWrapperEntry>("personWrapper", person)
    ? resolveEntry(includes, person.fields.bioURL) ||
      resolveEntry(
        includes,
        resolveEntry(includes, person.fields.person)?.fields.bioURL,
      )
    : person
      ? resolveEntry(includes, person.fields.bioURL)
      : undefined;
  if (!url && bioUrl) {
    url = resolveEntryUrl({ domain: domain, entry: bioUrl, includes }) || "";
  }

  if (
    !url &&
    !isContentType<PersonWrapperEntry>("personWrapper", person) &&
    person?.fields.hbsEnterpriseID &&
    person.fields.personType
  ) {
    const isFaculty = resolveEntries({
      includes,
      refs: person.fields?.personType,
    }).some((type) => type.fields.title === "HBS Faculty");

    if (isFaculty) {
      url =
        "https://www.hbs.edu/faculty/Pages/profile.aspx?facId=" +
        person.fields.hbsEnterpriseID;
    }
  }

  return url;
}
