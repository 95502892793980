import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import dynamic from "next/dynamic";
import type * as ContentfulLiveImport from "@contentful/live-preview/react";
import { usePageProps } from "./use-page-props";
import {
  AssetRef,
  ContentfulAsset,
  ContentfulEntry,
  EntryRef,
} from "frontend/contentful/schema/sys";
import {
  resolveAsset,
  resolveEntry,
} from "frontend/contentful/lib/resolve-includes";
import { editAttributesFactory } from "frontend/contentful/lib/edit-attributes-factory";
import {
  ResolveEntriesOptions,
  resolveEntryUrl,
} from "frontend/contentful/lib/resolve-entry-url";
import { AppUrlEntry } from "frontend/contentful/schema/app";
import {
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { resolvePersonLink } from "frontend/contentful/lib/resolve-person-link";
import { resolvePersonName } from "frontend/contentful/lib/resolve-person-name";
import { resolveEntries } from "frontend/contentful/lib/resolve-entries";
import { resolveAssets } from "frontend/contentful/lib/resolve-assets";

const ContentfulToolbox = dynamic(
  () =>
    import(
      "frontend/contentful/components/utils/contentful-toolbox/contentful-toolbox"
    ).then((e) => ({
      default: e.ContentfulToolbox,
    })), // do not ssr - will break due to portal rendering
  { ssr: false },
);

interface ContentfulLivePreviewProps {
  isPreview?: boolean;
  children: ReactNode;
}

interface ContentfulEditorState {
  isPageLoaded: boolean;
  isEditorMode: boolean;
  setIsEditorMode: Dispatch<SetStateAction<boolean>>;
  useContentfulLiveUpdates: typeof ContentfulLiveImport.useContentfulLiveUpdates;
  useContentfulInspectorMode: typeof ContentfulLiveImport.useContentfulInspectorMode;
  ContentfulLivePreviewProvider?: typeof ContentfulLiveImport.ContentfulLivePreviewProvider;
}

const createInitialState = (): ContentfulEditorState => ({
  isPageLoaded: false,
  isEditorMode: false,
  setIsEditorMode: (s) => s,
  useContentfulLiveUpdates: (e: ContentfulEntry) => e,
  useContentfulInspectorMode: () => () => null,
});

type ContentfulEditorContextState = [
  ContentfulEditorState,
  Dispatch<SetStateAction<ContentfulEditorState>>,
];

const ContentfulEditorContext = createContext<ContentfulEditorContextState>([
  createInitialState(),
  (s) => s,
]);

export function ContentfulLivePreview({
  isPreview,
  children,
}: ContentfulLivePreviewProps) {
  const [state, setState] =
    useState<ContentfulEditorState>(createInitialState());

  useEffect(() => {
    // Contentful site preview is inside an iframe
    const isEditorMode = isPreview ? window.self !== window.top : false;
    setState((state) => ({ ...state, isPageLoaded: true, isEditorMode }));
  }, [isPreview]);

  useEffect(() => {
    // lazy import the hooks
    if (state.isEditorMode && !state.ContentfulLivePreviewProvider) {
      void (async () => {
        try {
          // we want to only import the live-preview library when we are in editor mode
          const lib = await import("@contentful/live-preview/react");

          setState((state) => ({
            ...state,
            ContentfulLivePreviewProvider: lib.ContentfulLivePreviewProvider,
            useContentfulInspectorMode: lib.useContentfulInspectorMode,
            useContentfulLiveUpdates: lib.useContentfulLiveUpdates,
          }));
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [state]);

  return (
    <ContentfulEditorContext.Provider value={[state, setState]}>
      {state.ContentfulLivePreviewProvider ? (
        <state.ContentfulLivePreviewProvider
          locale="en-US"
          debugMode
          enableInspectorMode
          enableLiveUpdates
          targetOrigin="https://app.contentful.com"
        >
          {children}
        </state.ContentfulLivePreviewProvider>
      ) : (
        children
      )}

      {isPreview && <ContentfulToolbox />}
    </ContentfulEditorContext.Provider>
  );
}

type GetEntryUrl = <E extends AppUrlEntry>(
  entry?: EntryRef<E>,
  options?: ResolveEntriesOptions,
) => string | undefined;

export function useContentful() {
  const [state] = useContext(ContentfulEditorContext);

  const {
    contentful,
    includes,
    request,
    site,
    locale: entryLocale,
    defaultLocale,
    supportedLocales,
  } = usePageProps();

  const createEditAttributes = editAttributesFactory(state.isEditorMode);

  const createContentfulHref = (path?: string) => {
    return `https://app.contentful.com/spaces/${contentful.spaceId}/environments/${contentful.environment}${path}`;
  };

  const getEntry = useCallback(
    <E extends ContentfulEntry>(ref?: EntryRef<E>) => {
      return resolveEntry(includes, ref);
    },
    [includes],
  );

  const getSite = () => getEntry(site);

  const getEntries = useCallback(
    <E extends ContentfulEntry>(refs?: Array<EntryRef<E>>): Array<E> => {
      return resolveEntries({ refs, includes });
    },
    [includes],
  );

  const getAsset = (ref?: AssetRef) => {
    return resolveAsset(includes, ref);
  };

  const getAssets = (refs?: Array<AssetRef>): Array<ContentfulAsset> => {
    return resolveAssets({ refs, includes });
  };

  const getEntryUrl: GetEntryUrl = (
    entry,
    { excludeProtocol, absolute, locale } = { locale: entryLocale },
  ) => {
    return resolveEntryUrl({
      domain: request.domain,
      entry: getEntry(entry),
      includes,
      excludeProtocol,
      absolute,
      locale,
      defaultLocale,
      supportedLocales,
    });
  };

  const getPersonLink = (
    entry?: EntryRef<
      PersonEntry | PersonWrapperEntry | SchoolLeadershipPersonDetailsEntry
    >,
  ): string => {
    return resolvePersonLink({ entry, includes, domain: request.domain });
  };

  const getPersonName = (
    entry?: EntryRef<
      PersonEntry | PersonWrapperEntry | SchoolLeadershipPersonDetailsEntry
    >,
  ): string => {
    return resolvePersonName({ entry, includes });
  };

  return {
    ...contentful,
    site: getSite(),
    getEntry,
    getAsset,
    getAssets,
    getEntries,
    getEntryUrl,
    getPersonLink,
    getPersonName,
    createEditAttributes,
    createContentfulHref,
    isPageLoaded: state.isPageLoaded,
    isEditorMode: state.isEditorMode,
    useContentfulLiveUpdates: state.useContentfulLiveUpdates,
    useContentfulInspectorMode: state.useContentfulInspectorMode,
  };
}
